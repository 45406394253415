<template>
  <!-- 发票详情页 -->
  <div class="bill">
    <div class="bill-title" @click="$router.go(-1)">
      发票详情
    </div>
    <!-- 已完成 1 -->
    <div class="bill-status" v-if="status === 1">已完成</div>
    <div class="bill-content">
      <el-row>
        <el-col :span="2"><span class="bill-sub-title">发票金额:</span></el-col>
        <el-col :span="18">￥{{billMsg.payAmount}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2"><span class="bill-sub-title">发票类型:</span></el-col>
        <el-col :span="18">增值税电子发票</el-col>
      </el-row>
      <el-row>
        <el-col :span="2"><span class="bill-sub-title">申请日期:</span></el-col>
        <el-col :span="18">{{billMsg.created}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2"><span class="bill-sub-title">发票抬头:</span></el-col>
        <el-col :span="18">{{billMsg.billHeader}}</el-col>
      </el-row>
      <el-row v-if="status !== 4">
        <el-col :span="2"><span class="bill-sub-title">纳税人识别号:</span></el-col>
        <el-col :span="18">{{billMsg.billIdentifyNum}}</el-col>
      </el-row>
      <el-row v-if="status !== 4">
        <el-col :span="2"><span class="bill-sub-title">地址:</span></el-col>
        <el-col :span="18">{{billMsg.businessAddress==null?'-':billMsg.businessAddress}}</el-col>
      </el-row>
      <el-row v-if="status !== 4">
        <el-col :span="2"><span class="bill-sub-title">电话:</span></el-col>
        <el-col :span="18">{{billMsg.billReceiverPhone}}</el-col>
      </el-row>
      <el-row v-if="status !== 4">
        <el-col :span="2"><span class="bill-sub-title">开户行:</span></el-col>
        <el-col :span="18">{{billMsg.billBankName==null?'-':billMsg.billBankName}}</el-col>
      </el-row>
      <el-row v-if="status !== 4">
        <el-col :span="2"><span class="bill-sub-title">开户账号:</span></el-col>
        <el-col :span="18">{{billMsg.billBankAccount==null?'-':billMsg.billBankAccount}}</el-col>
      </el-row>
      <el-row>
        <el-col :span="2"><span class="bill-sub-title">发票内容:</span></el-col>
        <el-col :span="18">{{billMsg.billContent==null?'-':billMsg.billContent}}</el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { httpGet } from "@/utils/httpRequest";
export default {
  name: "Bill",
  data() {
    return {
      status: "",
      billMsg:""
    }
  },
  mounted() {
    this.getBillMsg()
  },
  methods:{
    getBillMsg(){
      this.status = this.$route.params.type
      // console.log(this.status)
      // console.log(this.$route.params.billMsgId)
      httpGet("/app/app-user-bill-msg/detail",
      {
        billMsgId: this.$route.params.billMsgId,
      },
      (res)=>{
        // console.log(res)
        this.billMsg = res.data
      },(err)=>{

      })
    }
  }
}
</script>

<style lang="scss" scoped>
.bill {
  padding: 0 3.3862%;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  .bill-title {
    width: 100%;
    height: 4rem;
    line-height: 4rem;
    text-align: left;
    font-size: 1.25rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    display: flex;
    align-items: center;
    border-bottom: 0.0625rem solid #EDEFF2;
  }

  .bill-title::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/icon-arrowL-24.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }

  .bill-status {
    width: 100%;
    text-align: left;
    display: flex;
    padding: 1.3125rem 0;
    font-size: 0.875rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
    border-bottom: 1px solid #EDEFF2;
  }

  .bill-status-1 {
    width: 100%;
    text-align: left;
    display: flex;
    padding: 1.3125rem 0 0 0;
    font-size: 0.875rem;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #000000;
  }

  .bill-status-content {
    margin-left: 3.59788%;
    margin-top: 0.6875rem;
    padding-bottom: 1.25rem;
    // height: 2.75rem;
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #667280;
    line-height: 1.375rem;
  }

  .bill-status-1::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/success.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }

  .bill-status::before {
    content: "";
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    background: url('~@/assets/img/pre/Ellipse 34.png');
    background-size: 100% 100%;
    margin-right: 0.625rem;
  }

  .bill-content {
    margin-top: 1.25rem;
    text-align: right;
    height: 1.375rem;
    font-size: 0.875rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 1.375rem;

    .bill-sub-title {
      text-align: right;
      height: 1.375rem;
      font-size: 0.875rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #667280;
      line-height: 1.375rem;
    }

    ::v-deep .el-col-18 {
      text-align: left;
      padding-left: 0.625rem;
      margin-bottom: 0.625rem;
    }
    .bill-content-recall
    {
      width: 100%;
      border-top: 0.0625rem solid #EDEFF2;
      margin-top: 1.25rem;
      .apply {
      width: fit-content;
      padding: 0 1.8161%;
      height: 2rem;
      background: #1371E7;
      box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
      border-radius: 10px;
      font-size: 0.8125rem;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 2rem;
      text-align: center;
      margin-top: 1.25rem;
    }
    }
  }
}
</style>